import React from 'react'
import styles from './KoFi.module.css'

export default function KoFi(props) {
  const { color, id, label } = props;
  return (
    <div className={styles.kofi_btn_container}>
      <a
        title={label}
        className={styles.kofi_button}
        style={{ backgroundColor: color }}
        href={'https://ko-fi.com/' + id}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span className={styles.kofitext}>
          <img
            src='https://ko-fi.com/img/cup-border.png'
            className={styles.kofiimg}
            alt='Ko-Fi button'
          />
          {label}
        </span>
      </a>
    </div>
  );
}